import React from 'react';
import {
    Box,
    Heading
} from "@chakra-ui/react";


const index = () => {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Heading>HELLLOO</Heading>
        </Box>
    )
}

export default index