
import {
    Box,
    useColorModeValue,
    SimpleGrid,
    Icon,
    Flex,
    Select


} from "@chakra-ui/react";


import IconBox from "components/icons/IconBox";
import {MdAddTask,
    MdAttachMoney,
    MdBarChart,
    MdFileCopy,
} from "react-icons/md";
import React from "react";
import Counter from "./subComponents/Counters";
import Brands from "./subComponents/brands";


// import Tasks from "views/admin/keyword/components/Tasks";


export default function CounterfeitSellers() {
    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");



    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Counter />
            <Brands />

            
        </Box>
    );
}
